.oa-day-picker {
  font-family: Inter, sans-serif, Arial;

  table {
    width: 100%;
    tbody tr td {
      text-align: center;
      button {
        font-family: Inter, sans-serif, Arial;
        width: 100%;
        min-height: 3rem;
        // background: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        padding: 0;
      }
    }
  }

  .rdp-caption {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
}

.font-family-inter {
  font-family: 'Inter', sans-serif;
}

.font-family-figtree {
  font-family: 'Figtree', sans-serif;
}

.oa-day-picker table tbody tr td button.rdp-day_range_middle {
  background-color: #f4f6f5;
  color: #0a1017;
  border-radius: 0;
  border: 1px solid #f4f6f5;
}

button {
  -webkit-tap-highlight-color: transparent;
}
