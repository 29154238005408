* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Figtree', 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
  background-color: #fdfdfd;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
}

/* Global styles to hide all scrollbars */
html,
body,
div,
section,
article,
aside,
nav,
header,
footer,
main {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
section::-webkit-scrollbar,
article::-webkit-scrollbar,
aside::-webkit-scrollbar,
nav::-webkit-scrollbar,
header::-webkit-scrollbar,
footer::-webkit-scrollbar,
main::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}

img {
  pointer-events: none; /* Disables image dragging and context menu for images */
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

@import 'react-day-picker.scss';

// slick slider reduce space beetween dots
.slick-dots li {
  margin: 0;
  width: 12px;
}
.slick-dots li button:before {
  font-size: 8px;
}
